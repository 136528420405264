import { forwardRef } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import '../Staff.scss';

const Publications = forwardRef((props, ref) => {
  return (
    <article ref={ref} className='staff-article' lang='ru'>
      <h2>Публикации</h2>
      <Tabs isFitted position='relative' variant='enclosed' width={'100%'}>
        <TabList
          gap={1}
          style={{ flexWrap: 'wrap', wordBreak: 'normal', hyphens: 'auto' }}
        >
          {[
            'Научные статьи в рецензируемых изданиях',
            'Учебные книги и пособия',
            'Словарные статьи',
            'Статьи в научных сборниках и монографиях',
          ].map((text) => (
            <Tab
              key={text}
              bg='#e1e1e1'
              _selected={{ backgroundColor: '#C4EAA5' }}
              fontSize={{
                base: '0.75rem',
                sm: '0.8rem',
                md: '0.9rem',
                lg: '1rem',
                xl: '1.1rem',
              }}
              padding={{ base: '2', md: '3', xl: '4' }}
              style={{
                alignContent: 'flex-start',
                flexWrap: 'wrap',
                lineHeight: '1',
                color: '#121d47',
                fontFamily: '"PT Sans", sans-serif',
                fontStyle: 'normal',
                fontWeight: 700,
                textAlign: 'left',
                maxWidth: 'fit-content',
              }}
            >
              {text}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel padding={0}>
            <br />
            <ol>
              <li>
                Миронова К.В. Установка педагога на творческое взаимодействие
                как условие успешности процесса изучения лирической поэзии с
                подростками // Мир психологии. 2023. № 2 (113). С. 165-172. doi:
                10.51944/20738528_2023_2_165
              </li>
              <li>
                Миронова К.В., Борисенко Н.А., Шишкова С.В. Возрастная динамика
                понимания текста подростками 11–16 лет в процессе смыслового
                чтения с экрана и с листа // Вопросы психологии. 2023. № 1. С.
                27-39.
              </li>
              <li>
                Миронова К.В., Борисенко Н.А., Шишкова С.В. Понимание текста
                подростками 11-12 лет в процессе смыслового чтения с бумажных и
                электронных носителей // Вопросы психологии. – 2022. – № 2. – С.
                99-110.
              </li>
              <li>
                Борисенко Н.А., Миронова К.В., Шишкова С.В. Вклад научной школы
                Г.Г. Граник в психологию понимания: от бумажного текста к
                электронному // Теоретическая и экспериментальная психология. –
                2022. – Том 15. – № 3. – С. 170-186.
                DOI:10.24412/2073-0861-2022-3-170-186
              </li>
              <li>
                Миронова К.В. Психодидактические основы становления у подростков
                способности к пониманию лирической поэзии // Перспективы науки и
                образования. 2021. № 6 (54). С. 330-341. doi:
                10.32744/pse.2021.6.22
              </li>
              <li>
                Граник Г.Г., Борисенко Н.А., Миронова К.В., Шишкова С.В.
                Функционирование воображения подростков при чтении бумажных и
                электронных текстов // Вопросы психологии. 2021. № 1. Т. 67. С.
                38-50.
              </li>
              <li>
                Mironova K. The Principle of Open Individuality as a Basis for
                Teenagers’ Creative Interaction with Lyric Poems // Psychology
                in Russia: State of the Art. 2019. Vol. 12 (4). Рр. 135–147.
                DOI: 10.11621/pir.2019.0408
              </li>
              <li>
                Панов В.И., Борисенко Н.А., Миронова К.В., Шишкова С.В.
                Поведение подростков в цифровой образовательной среде: к
                определению понятий и постановке проблемы // Известия
                Саратовского университета. – Новая серия. Акмеология
                образования. Психология развития. 2021. № 3. С.188-196. 1 п.л.
                DOI:10.18500/2304-9790-2021-10-3-188-196.
              </li>
              <li>
                Борисенко Н.А., Миронова К.В., Шишкова С.В., Граник Г.Г.
                Особенности цифрового чтения современных подростков: результаты
                теоретико-эмпирического исследования // Science for Education
                Today. 2020. Т. 10, № 5. С. 28–49. DOI:
                10.15293/2658-6762.2005.02
              </li>
              <li>
                Миронова К.В. Теоретико-экспериментальное исследование проблемы
                развития у подростков понимания лирической поэзии // Мир
                психологии. 2019. № 2. С. 217-230.
              </li>
              <li>
                Миронова К.В. «Таинственный певец»: беседа на уроке о
                стихотворении А.С. Пушкина «Арион» // Русский язык в школе.
                2020. Т. 81. № 4. С. 34–41. DOI:
                10.30515/0131-6141-2020-81-4-34-41
              </li>
              <li>
                Миронова К.В. Всероссийская научная конференция «Психодидактика
                современного учебника: преемственность традиций и векторы
                развития» // Мир психологии. 2019. № 1. С. 288-290.
              </li>
              <li>
                Миронова К.В. Обсуждение стихотворения А.С. Пушкина «Пророк» на
                страницах учебной книги нового типа // Русский язык в школе.
                2017. № 1. С. 22-26.
              </li>
              <li>
                Миронова К.В. Психодидактические принципы развития понимания
                лирической поэзии подростками посредством учебных книг нового
                типа // Мир психологии. 2016. № 3. С. 273-283.
              </li>
              <li>
                Миронова К.В. Развитие понимания подростками лирической поэзии
                (на материале стихотворения А.С. Пушкина «Зимнее утро») //
                Русский язык в школе. 2015. № 12. С. 22-25.
              </li>
              <li>
                Миронова К.В. Стихотворение М.Ю. Лермонтова «Тучи» // Литература
                в школе. 2011. № 3. С. 18-19.
              </li>
              <li>
                Миронова К.В. Психолого-дидактическое исследование проблемы
                восприятия лирической поэзии учащимися подросткового возраста //
                Теоретическая и экспериментальная психология. 2010. Т.3. № 2. С.
                37-43.
              </li>
              <li>
                Миронова К.В. Стихотворение М.Ю. Лермонтова «Когда волнуется
                желтеющая нива…» // Литература в школе. 2009. № 7. С. 31-34.
              </li>
            </ol>
            <br />
          </TabPanel>
          <TabPanel padding={0}>
            <br />
            <ol>
              <li>
                Миронова К.В. Проверочно-обучающая методика «Диагностика и
                повышение уровня понимания подростками лирической поэзии». М.:
                ПИ РАО, 2020. 54 с.
              </li>
              <li>
                Миронова К.В. Беседы о лирике А. С. Пушкина / В кн.: Граник Г.
                Г., Концевая Л. А., Миронова К. В. А. С. Пушкин, его друзья и
                современники: Учебное пособие по литературе для учащихся 7-9
                классов общеобразовательных организаций. М.: Мнемозина, 2019;
                2023 (2-е изд.). С. 192-264.
              </li>
              <li>
                Миронова К.В. Одиннадцать поэтических шедевров / В кн.: Граник
                Г.Г., Концевая Л.А., Динерштейн Е.Е., Миронова К.В. // Я –
                другой. М. Ю. Лермонтов: Учебная книга по русской филологии. М.,
                2012. С. 166-215.
              </li>
              <li>
                Миронова К.В. Программа обучения дошкольников китайскому языку и
                культуре Китая. – М.: ВКН, 2017. М.: ВКН, 2018, 2019, 2021 (2-е
                изд., испр. и доп.), 2022 (3-е изд.).
              </li>
            </ol>
            <br />
          </TabPanel>
          <TabPanel padding={0}>
            <br />
            <ol>
              <li>
                Миронова К.В. Вдумчивое чтение. Внимательное чтение. Выборочное
                чтение. Глубокое чтение. Чтение стихов // Чтение.
                Энциклопедический словарь / под ред. Ю.П. Мелентьевой. М.: ФГБУН
                НИЦ «Наука» РАН, 2021. С. 22-23, 25, 30-31, 34-35, 405-407.
              </li>
            </ol>
            <br />
          </TabPanel>
          <TabPanel padding={0}>
            <br />
            <ol>
              <li>
                Развитие у школьников культуры чтения с использованием учебной
                книги психодидактического типа (на материале лирики) // Доклады
                Научного совета по проблемам чтения РАО. Вып. 20: К 80-летию
                РАО: По материалам Постоянного Круглого стола по проблемам
                чтения РАО / сост. акад. РАО, д-р пед. н., проф. Ю.П.
                Мелентьева; под ред. В.А. Лекторского. М.: ФГБУН РАН «Наука»,
                2023. С. 42-51
              </li>
              <li>
                Обучение школьников работе с учебной книгой психодидактического
                типа (на материале глав о лирике) // Берковские чтения – 2023.
                Книжная культура в контексте международных контактов: Материалы
                VII Международной научной конференции (Брест, 24–25 мая 2023 г.)
                / Сост.: Л.А. Авгуль, Н.В. Вдовина: в 2 т. М.: ФГБУН НИЦ «Наука»
                РАН, Минск: ЦНБ НАН Беларуси; 2023. Т. 2. С. 44-49. DOI:
                10.52929/9785604821862_44
              </li>
              <li>
                Психологические аспекты учебного сотрудничества на уроках
                изучения лирической поэзии с подростками // XXVI Царскосельские
                чтения. Материалы междунар. науч. конференции. Санкт-Петербург:
                ЛГУ им. А.С. Пушкина, 2022. Т.2. С. 164-167.{' '}
              </li>
              <li>
                Учебная книга психодидактического типа и читатель-подросток:
                смыслообразующее взаимодействие с лирическими произведениями //
                Общение в эпоху конвергенции технологий: сб. науч. трудов
                [Электронный ресурс] / отв. ред. Карпова Н.Л., Петрова Е.А.,
                Зотова О.В. М.: ФГБНУ «ПИ РАО», 2022. С. 472-475. DOI:
                10.24412/cl-36917-2022-472-475{' '}
              </li>
              <li>
                Медиамногозадачность подростков в процессе обучения: риски и
                преимущества // Семья и дети в современном мире. Сборник
                материалов конференции «Семья и дети в современном мире». Т. VII
                / под общ. и науч. ред. д-ра психол. н., проф. В.Л. Ситникова.
                Кызыл: Изд-во ТувГУ, 2022. С. 588-593.
              </li>
              <li>
                Трудности при обучении подростков чтению лирики: разговор с
                учителями // Современные проблемы книжной культуры: основные
                тенденции и перспективы развития: материалы XV
                Белорусско-Российского научного семинара-конференции. Минск; М.,
                2022. С. 316-321.
              </li>
              <li>
                Ценностно-смысловое взаимодействие подростков с лирическими
                произведениями в процессе формирующего оценивания //
                Герценовские чтения: психологические исследования в образовании.
                2021. Вып. 4. С. 431-439. DOI: 10.33910/herzenpsyconf-2021-4-53
              </li>
              <li>
                Цифровизация образования и воспитание исследовательской
                честности у подростков // Социальная педагогика. 2021. № 3. С.
                47-49.
              </li>
              <li>
                Чтение лирики в подростковом возрасте как личностно и социально
                значимая практика // Доклады Науч. совета по проблемам чтения
                РАО. Вып. 18: По материалам Постоянного Круглого стола по
                проблемам чтения РАО / сост. член-корр. РАО, д-р пед. наук,
                проф. Ю.П. Мелентьева; под ред. акад. РАН и РАО В.А.
                Лекторского. М.: ФГБУН РАН «Наука», 2021. С. 32-42.
              </li>
              <li>
                Развитие ценностно-смысловой сферы подростков в условиях
                цифрового образовательного пространства (на материале изучения
                лирической поэзии) // XXV юбилейные Царскосельские чтения:
                материалы межд. науч. конф., Санкт-Петербург, 20–21 апр. 2021 г.
                / под общ. ред. С.Г. Еремеева. СПб.: ЛГУ им. А.С. Пушкина, 2021.
                Т. III. С. 180-183.
              </li>
              <li>
                Психодидактическая подготовка учителей к проведению занятий,
                способствующих развитию у подростков понимания лирической поэзии
                // Герценовские чтения: психологические исследования в
                образовании. 2020. Выпуск 3. СПб., 2020. С. 459-466. DOI:
                10.33910/herzenpsyconf-2020-3-47{' '}
              </li>
              <li>
                Уроки изучения лирики как микроуровень образовательной среды,
                способствующей раскрытию творческого потенциала подростков //
                Экопсихологические исследования-6: экология детства и психология
                устойчивого развития: сборник научных статей / отв. ред. В.И.
                Панов. М.: ПИ РАО; Курск: Университетская книга, 2020. С.
                324-328.{' '}
              </li>
              <li>
                Лирическое произведение как театральное действо: беседа о
                стихотворении А.С. Пушкина «Туча» // Психология. Литература.
                Театр. Кино: колл. монография / ПИ РАО, Литинститут им. А.М.
                Горького; под общ. ред. Н.Л. Карповой; редкол.: К.В. Миронова,
                Н.А. Борисенко, С.Ф. Дмитренко. М.: Ассоциация школьных
                библиотекарей русского мира (РШБА), 2020. С. 147-150.
              </li>
              <li>
                Воспитание у подростков исследовательской честности в условиях
                цифровизации образования // Семья и дети в современном мире. Сб.
                материалов конференции «Семья и дети в современном мире». Том VI
                / под общ. и науч. ред. проф. В.Л. Ситникова. СПб.: Изд-во РГПУ
                им. А. И. Герцена, 2020. С. 972-976.
              </li>
              <li>
                В.В. Набоков: вырастающий из Родины // Литературное творчество и
                наука. Пересечения. К 30-летию Союза литераторов РФ. М., 2020.
                С. 90-102.{' '}
              </li>
              <li>
                Mironova K.V. Psychodidactic principles of developing teenagers’
                comprehension of lyric poetry. // Book of Abstracts: XVI
                European Congress of Psychology (ECP 2019) (2‒5 July, 2019,
                Lomonosov Moscow State University, Moscow). Moscow: Moscow
                University Press, 2019. Pp. 1052-1053.
              </li>
              <li>
                Психодидактические основы организации эффективного
                взаимодействия на уроках изучения лирики // Сборник статей
                «Наука – образование – профессия: системный
                личностно-развивающий подход» / Под общ. ред. Л.М. Митиной. М.:
                Издательство «Перо», 2019. С. 188-191.
              </li>
              <li>
                Психодидактические основы формирования у подростков навыков
                оценки лирического произведения с помощью учебной книги нового
                типа // Психодидактика современного учебника: преемственность
                традиций и векторы развития; отв. ред. Н.А. Борисенко, сост.
                Н.А. Борисенко, К.В. Миронова, С.В. Шишкова. М.: Мнемозина,
                2019. С. 111-120.{' '}
              </li>
              <li>
                Развитие у подростков целостного восприятия лирической поэзии //
                Материалы Международного молодежного научного форума
                «ЛОМОНОСОВ-2019» / Отв. ред. И.А. Алешковский, А.В. Андриянов,
                Е.А. Антипов. [Электронный ресурс]. М.: МАКС Пресс, 2019.{' '}
              </li>
              <li>
                Оценивание уровня понимания подростками лирической поэзии //
                Психологические исследования: Вып. 9. М.: Изд-во «Институт
                психологии РАН», 2018. С. 87-98.{' '}
              </li>
              <li>
                Теоретико-экспериментальные исследования проблемы понимания
                подростками лирической поэзии // Как учить русскому языку и
                литературе современных школьников? Школьный учебник сегодня:
                Коллективная монография. М., СПб.: Нестор-История, 2018. С.
                149-169.{' '}
              </li>
              <li>
                Психодидактические принципы развития у подростков понимания
                лирической поэзии // Как учить русскому языку и литературе
                современных школьников? Школьный учебник сегодня: Коллективная
                монография. М., СПб.: Нестор-История, 2018. С. 169-199.{' '}
              </li>
              <li>
                Приобщение школьников к исследованию лирики с помощью учебных
                книг нового типа // Как учить русскому языку и литературе
                современных школьников? Школьный учебник сегодня: Коллективная
                монография. М., СПб.: Нестор-История, 2018. С. 199-209.{' '}
              </li>
              <li>
                Развитие у подростков эмоциональной отзывчивости на лирическую
                поэзию // Материалы Международного молодежного научного форума
                «ЛОМОНОСОВ-2018» / Отв. ред. И.А. Алешковский, А.В. Андриянов,
                Е.А. Антипов. [Электронный ресурс] М.: МАКС Пресс, 2018.{' '}
              </li>
              <li>
                Многомерное исследование с подростками поэтического текста //
                Одаренность: методы выявления и пути развития. Сборник статей,
                докладов и материалов Всероссийской конференции. М.: МГТУ им.
                Н.Э. Баумана, 2018. С. 84-89.{' '}
              </li>
              <li>
                Беседа о стихотворении А.С. Пушкина "Няне" // Русский язык в
                школе и дома. 2017. № 10. С. 12-14.{' '}
              </li>
              <li>
                Измерение понимания подростками поэтического текста //
                Психология – наука будущего: Материалы VII Международной
                конференции молодых ученых "Психология – наука будущего". 14-15
                ноября 2017 года, Москва. М., 2017. С. 547-551.{' '}
              </li>
              <li>
                Формирующее оценивание понимания подростками поэтического текста
                // Личность, интеллект, метакогниции: исследовательские подходы
                и образовательные практики. Материалы II-й Международной
                научно-практической конференции 20-22 апреля 2017 г., Калуга,
                Россия. Калуга, 2017. С. 543-548.{' '}
              </li>
              <li>
                Беседа о стихотворении А.С. Пушкина «Я вас любил: любовь еще,
                быть может…» // Русский язык в школе и дома. 2017. № 2. С.
                12-14.{' '}
              </li>
              <li>
                Проблема измерения понимания подростками поэтического текста.
                [Электронный ресурс] // Материалы молодежной конференции
                «Смирновские чтения-2016». М.: ПИ РАО, 2016. С. 41-45.{' '}
              </li>
              <li>
                Инновационный подход к построению учебных книг, развивающих
                понимание подростками художественного текста // ХХ юбилейные
                Царскосельские чтения: материалы междунар. науч. конф., 20-21
                апр. 2016. Т. 2. СПб.: ЛГУ им. А.С. Пушкина, 2016. Т. II. С.
                93-96.{' '}
              </li>
              <li>
                Реализация в учебной книге принципа стимулирования эмоциональной
                включенности подростков в лирическое повествование // Психология
                и литература в диалоге о человеке: коллективная монография / под
                ред. Н.А. Борисенко, Н.Л. Карповой, С.Ф. Дмитренко. М.: РШБА,
                2016. С. 90-93.{' '}
              </li>
              <li>
                Операционализация конструкта «Понимание лирической поэзии» //
                Журнал Международного института чтения им. А.А. Леонтьева, №№
                12-15. Психология, философия и педагогика чтения. М.: НИЦ
                ИНЛОККС, 2016. С. 220–222.{' '}
              </li>
              <li>
                Роль учебной книги в формировании у подростков потребности в
                чтении лирики // Личность, интеллект, метакогниции:
                исследовательские подходы и образовательные практики. Материалы
                I-й Всероссийской научно-практической конференции с
                международным участием 20-21 мая 2016 г., Калуга, Россия.
                Калуга, 2016. С. 275-281.
              </li>
              <li>
                Психодидактические принципы развития понимания лирической поэзии
                подростками с помощью учебных книг нового типа / Материалы VI
                Международной конференции молодых ученых «Психология – наука
                будущего». 19-20 ноября 2015 г., Москва. М.: ИП РАН, 2015. С.
                296-300.
              </li>
              <li>
                Активизация личностно значимого взаимодействия подростков с
                поэтическим текстом // Материалы научно-практической молодежной
                конференции «Смирновские чтения-2015». М.: ФГБНУ ПИ РАО, 2015.
                С. 57-60.
              </li>
              <li>
                Развитие понимания лирической поэзии подростками с помощью
                учебных книг психодидактического типа // Материалы
                Международного молодежного научного форума «ЛОМОНОСОВ-2015» /
                Секция "Психология". Отв. ред. А.И. Андреев, А.В. Андриянов,
                Е.А. Антипов. [Электронный ресурс] М.: МАКС Пресс, 2015.{' '}
              </li>
              <li>
                Психодидактические аспекты построения учебных книг по литературе
                // Материалы молодежной конференции «Смирновские чтения-2014».
                Москва: ФГБНУ ПИ РАО, 2014. [Электр. ресурс]. С. 12-13.{' '}
              </li>
              <li>
                Беседы о лирике на страницах учебной книги «Я – другой. М.Ю.
                Лермонтов» // Художественное сознание: консолидация
                естественнонаучного и гуманитарного подходов: материалы
                Всероссийской научной конференции с международным участием,
                посвященной 200-летию со дня рождения М.Ю. Лермонтова. Самара,
                12-14 марта 2014 г. / под ред. Г.В. Акопова. Самара: ПГСГА,
                2014. С. 273-275.
              </li>
              <li>
                Диалектика взаимодействия значения и смысла в понимании
                лирического стихотворения // Доклады Научного совета по
                проблемам чтения РАО. Вып. 9-10. По материалам постоянного
                семинара по проблемам чтения / Под ред. В.А. Лекторского, Ю.П.
                Мелентьевой, Т.С. Маркаровой. М.: Изд-во «Канон+», 2014. С.
                152-153.{' '}
              </li>
              <li>
                Учебная книга по литературе и ее возможности в приобщении
                подростков к чтению лирической поэзии // Книга в информационном
                обществе. Материалы XIII Международной научной конференции по
                проблемам книговедения (Москва, 28-30 апреля 2014 г.). М.: ФГБУ
                «Наука» РАН, 2014. С. 276-277.{' '}
              </li>
              <li>
                Роль учебных книг психодидактического типа в развитии понимания
                подростками лирической поэзии // Психология XXI века. Психология
                и современные проблемы образования: Сборник материалов IX
                международной научно-практической конференции молодых ученых,
                ЛГУ им. А.С. Пушкина, 27-28 февраля 2014 г. СПб, 2014. С. 80-85.
              </li>
              <li>
                Близкое и чуждое: два облика Родины М.Ю. Лермонтова //
                Словесность 2013. Альманах. Книга 7. М., 2013.С. 43-45.{' '}
              </li>
              <li>
                Развитие у подростков личностно значимого отношения к лирической
                поэзии // Теоретические исследования психологии и педагогики:
                Материалы II Международной научно-практической конференции для
                студентов, аспирантов и молодых ученых. М., 2012.{' '}
              </li>
              <li>
                Психолого-дидактические особенности построения учебных книг по
                литературе (на материале лирики М.Ю. Лермонтова) // Журнал
                Международного института чтения им. А.А. Леонтьева № 11. Чтение
                и познание. Материалы 16-й научно-практической конференции по
                психологии, философии и педагогике чтения 1–3 февраля 2012 года,
                РГПУ им. А.И. Герцена, Санкт-Петербург; 4–5 апреля 2012 года,
                Психологический институт РАО, Москва. М., 2012. С. 85-87.{' '}
              </li>
              <li>
                Беседа о стихотворении М.Ю. Лермонтова «Молитва» («Я, Матерь
                Божия, ныне с молитвою…») // Русский язык. 2011. № 4. С. 32-34.{' '}
              </li>
              <li>
                Прощальное стихотворение М.Ю. Лермонтова «Выхожу один я на
                дорогу…» // Русский язык. 2011. № 16.{' '}
              </li>
              <li>
                Психолого-дидактические принципы формирования у школьников
                потребности в чтении художественной литературы // Международная
                научная конференция «Чтение в образовании и культуре». Доклады и
                тезисы / Сост. Ю.П. Мелентьева, Т.С. Маркарова / Под ред. В.А.
                Лекторского. М., 2011. С. 171-173.
              </li>
              <li>
                Психолого-дидактическое исследование проблемы восприятия
                поэтического текста школьниками подросткового возраста:
                материалы Междунар. молодежного науч. форума «Ломоносов-2010»
                (секция «Психология») [Электр. ресурс]. М.: МГУ, 2010.{' '}
              </li>
              <li>
                Развитие смыслового понимания лирической поэзии учащимися
                подросткового возраста посредством полилогического обсуждения //
                Современные проблемы психологии развития и образования человека:
                сб. матер. междунар. конф. СПб., 2010. Т.1. С. 273-276.{' '}
              </li>
              <li>
                Беседа о стихотворениях М.Ю. Лермонтова «Утес» и «На севере
                диком стоит одиноко…» // Русский язык. 2010. № 17. С. 34-37.{' '}
              </li>
              <li>
                Мотив родины в поэзии В.В. Набокова и М.Ю. Лермонтова как
                семантический вектор их внутреннего пути: материалы Междунар.
                молодежного науч. форума «Ломоносов-2007» (секция «Филология»)
                [Электр. ресурс]. М.: МГУ, 2007.{' '}
              </li>
              <li>
                Читаем поэзию М.Ю. Лермонтова // Психолого-дидактические основы
                создания учебников нового типа по русской филологии / под науч.
                ред. Г.Г. Граник. Москва-Обнинск, 2007. С. 164-199.
              </li>
            </ol>
            <br />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </article>
  );
});

export default Publications;
