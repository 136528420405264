import { forwardRef } from 'react';
import '../Staff.scss';

const Grants = forwardRef((props, ref) => {
    return (
        <article ref={ref} className='staff-article'>
            <h2>Участие в грантах</h2>
            <ul>
                <li>Грант РФФИ №19-29-14067мк «Коммуникативные взаимодействия и
                субъектность учащихся в условиях цифровизации образования: от
                экопсихологии к психодидактике» – исполнитель (руков. –  д.пс.н., член-
                корр. РАО, зав. лабораторией экопсихологии развития и психодидактики
                ПИ РАО В.И. Панов). 2019-2022.</li>
                <li>Грант РФФИ № 18-013-20085 «Проект организации научной
                конференции «Психодидактика современного учебника: преемственность
                традиций и векторы развития»». 2018.</li>
                <li>Грант РГНФ № 11-06-00646а «История и направления исследований
                чтения и грамотности в Психологическом институте РАО (к 100-летию
                института)» – исполнитель (руков. –  д.пс.н., проф. Н.Л. Карпова). 2012-
                2013.</li>
            </ul>
            <br />
        </article>
        );
});

export default Grants;