import { forwardRef } from 'react';
import '../Staff.scss';

const Bio = forwardRef((props, ref) => {
    return (
        <section ref={ref}>
            <article className='staff-article'>
                <h2>Биография</h2>
                <p>Родилась 2 марта 1961 г. в Полтаве. По месту службы отца-военнослужащего 
                    училась в разных школах Подмосковья, затем в Полтавском государственном 
                    педагогическом институте (по специальности «русский язык и литература»), 
                    который окончила в 1982 г.  В 1990 г., после окончания аспирантуры Института 
                    языкознания им. А.А. Потебни Академии наук Украины (г. Киев), защитила 
                    кандидатскую диссертацию (спец. 10.02.01 – «русский язык»).</p>
                <p>Работала в Полтавском государственном педагогическом институте 
                    (1982-1990), Авторском педагогическом центре Никитиных 
                    (г. Королёв Московской обл., 1995-2002), Московском институте 
                    открытого образования (1999-2000).</p>
                <p>С 2001 г. – сотрудник Психологического института РАО 
                    (группа психологических проблем построения школьных учебников 
                    (руков. Г.Г. Граник), с 2015 г. в составе лаборатории экопсихологии 
                    развития и психодидактики (руков. В.И. Панов)).</p>
            </article>
        </section>
        );
});

export default Bio;