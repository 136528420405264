import { forwardRef } from 'react';
import '../Staff.scss';

const Bio = forwardRef((props, ref) => {
    return (
        <section ref={ref} className='staff-article'>
            <article className='staff-article'>
                <h2>Биография</h2>
                <p>В 1994 г. закончила факультет педагогики и методики начального обучения Рязанского государственного педагогического университета, в 2005 г. – факультет профессиональной переподготовки педагогических кадров Московского института открытого образования по программе «Филология». Работала учителем в школах Москвы.</p>
                <p>С 2005 г. – сотрудник Психологического института РАО. (группа психологических проблем построения школьных учебников (руков. Г.Г. Граник), с 2015 г. в составе лаборатории экопсихологии развития и психодидактики).</p>
            </article>
        </section>
        );
});

export default Bio;