import './Home.scss';
import '../../../App.css';
import { Avatar, Link, Wrap, WrapItem } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Borisenko_avatar from '../assets/Borisenko_avatar.webp';
import Mironova_avatar from '../assets/Mironova_avatar.webp';
import Shishkova_avatar from '../assets/Shishkova_avatar.webp';

const avatarsData = [
  {
    name: 'Борисенко Н.А.',
    src: Borisenko_avatar,
    link: '/staff/borisenko',
  },
  {
    name: 'Миронова К.В.',
    src: Mironova_avatar,
    link: '/staff/mironova',
  },
  {
    name: 'Шишкова С.В.',
    src: Shishkova_avatar,
    link: '/staff/shishkova',
  },
];

function Home() {
  return (
    <main>
      <h1>О нас</h1>
      <article className='home-article'>
        <p>
          Мы,{' '}
          <Link color='#43129B' href='/staff'>
            сотрудники
          </Link>{' '}
          группы психологических основ разработки школьных учебников ПИ РАО,
          занимаемся двумя основными проблемами: 1) разработки учебников по
          русскому языку и учебных книг по литературе, построенных на
          психолого-дидактическом фундаменте; 2) чтения и понимания текста с
          листа и с экрана. Сайт «Русская филология» создан в развитие идей
          научной школы акад. РАО{' '}
          <Link color='#43129B' href='/teachers/#Granik'>
            Г.Г. Граник
          </Link>{' '}
          и наших собственных исследований. На сайте можно найти как научные
          публикации, так и практические рекомендации по различным вопросам
          обучения школьников филологическим дисциплинам.
        </p>
      </article>
      <Wrap
        spacing={{ base: 4, sm: 10, md: 20, lg: 24, xl: 40, '2xl': 52 }}
        padding={{ sm: 2, md: 5, lg: 5, xl: 10, '2xl': 10 }}
        paddingTop={{ base: 2 }}
        paddingBottom={{ base: 2 }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {avatarsData.map((data, index) => (
          <WrapItem key={index} flexDirection={'column'} alignItems={'center'}>
            <Avatar
              size={{
                base: 'md',
                sm: 'lg',
                md: 'xl',
                lg: 'xl',
                xl: '2xl',
                '2xl': '2xl',
              }}
              name={data.name}
              src={data.src}
              style={{ marginBottom: 10 }}
            />
            <h2>
              <Link href={data.link}>{data.name}</Link>
            </h2>
          </WrapItem>
        ))}
      </Wrap>
      <h1>История группы</h1>
      <article className='home-article'>
        <p>
          В 1962 г. в составе лаборатории проблем программированного обучения
          была создана группа «Психологические проблемы построения школьных
          учебников» под руков.{' '}
          <Link color='#43129B' href='/teachers/#Granik'>
            Г.Г. Граник
          </Link>
          . С 1975 г. группа существовала как самостоятельная единица (с 2015 г.
          – в составе лаборатории экопсихологии развития и психодидактики
          (руков. – д-р психол. н., член-корр. РАО В.И. Панов).
        </p>
        <p>
          Почти полвека (1962–2021) научный коллектив, возглавляемый академиком
          РАО, доктором психол. наук, профессором Г.Г. Граник, работал над
          созданием{' '}
          <Link color='#43129B' href='/research/#papertextbook'>
            школьных учебников
          </Link>{' '}
          нового типа по русскому языку и учебных книг по литературе, опираясь
          на психолого-дидактические исследования и собственную оригинальную
          концепцию.
        </p>
        <p>
          Созданию учебников предшествовал ряд фундаментальных и
          практико-ориентированных исследований. Среди них: исследования
          психологических механизмов грамотной речи, приемов понимания и
          запоминания учебного и художественного текста, развития воссоздающего
          воображения, психологических основ интеграции учебных курсов русского
          языка и литературы. Были определены основные принципы создания
          учебников нового типа по русскому языку и учебных книг литературе:
          сопровождение всего учебного процесса; учет физиологической специфики
          возраста; учет психологических закономерностей понимания и запоминания
          изучаемого материала; нацеленность на развитие творческого и
          воссоздающего воображения; развитие самоконтроля и мн. др.
        </p>
        <p>
          За эти годы разработаны два принципиально новых направления в
          педагогической психологии:
        </p>
        <ol>
          <li>
            <Link color='#43129B' href='/research/#papertextbook'>
              «Школьный учебник»
            </Link>{' '}
            (Г.Г. Граник, С.М. Бондаренко, Л.А. Концевая, Н.А. Борисенко, С.В.
            Шишкова);
          </li>
          <li>
            «Школьное литературоведение на психологической основе» (Г.Г. Граник,
            Л.А. Концевая, К.В. Миронова).
          </li>
        </ol>
        <p>
          Результатом научных изысканий явились создание и апробация целостного
          курса «Русская филология» для I и II ступеней образования. Написаны
          свыше 50 учебников и учебных пособий по русскому языку и литературе. С
          2009 по 2015 гг. учебники имели гриф Министерства образования и науки
          и входили в федеральный перечень учебников, в настоящее время они
          используются как учебные пособия.
        </p>
        <p>
          Основной целью{' '}
          <Link color='#43129B' href='/research/#papertextbook'>
            школьного курса
          </Link>{' '}
          русского языка в концепции Г.Г. Граник является формирование у
          учащихся положительной познавательной мотивации; понимания
          лингвистических закономерностей; воспитание культурного человека,
          свободно владеющего грамотной устной и письменной речью. В основу
          курса положены представления о функционировании психологических
          механизмов, общих для грамотного письма и восприятия художественной
          литературы: упреждение, удержание, прогнозирование, установка и т.д.
          Особое внимание уделяется проблеме работы учащихся с книгой.
        </p>
        <p>
          К числу исследований, проводимых в группе <b>в настоящее время</b>,
          относятся:
        </p>
        <ul>
          <li>
            переработка учебников по русскому языку для 5-9 классов в
            соответствии с требованиями ФГОС нового (третьего) поколения;
          </li>
          <li>
            разработка учебных материалов для цифровой образовательной платформы
            (на сайте издательства{' '}
            <Link color='#43129B' href='https://mnemozina.ru/' isExternal>
              «Мнемозина»
              <ExternalLinkIcon mx='2px' />
            </Link>
            ), с использованием уже изданных учебников по русскому языку, что
            позволит индивидуализировать процесс обучения, выстроить систему
            дистанционного обучения для разных групп учащихся;
          </li>
          <li>
            разработка и апробация системы психодидактических принципов развития
            у подростков понимания лирики и приемов практической реализации
            данных принципов в учебной книге по литературе (в рамках развития
            направления «Школьное литературоведение на психологической основе»);
          </li>
          <li>
            исследование специфики работы воссоздающего воображения в процессе
            восприятия текста с экрана и с листа;
          </li>
          <li>
            изучение чтения подростков в новых социокультурных условиях
            трансформации чтения в цифровую среду, в частности, чтения с экрана;
          </li>
          <li>
            эмпирическое исследование понимания текста подростками 5-9 классов в
            процессе смыслового чтения с бумажных и электронных носителей.
          </li>
        </ul>
        <p>
          Реализация в учебниках и учебных книгах этих подходов соответствует
          перспективным направлениям филологического образования и отражает его
          современные приоритеты.
        </p>
      </article>
    </main>
  );
}

export default Home;
