import "./Research.scss";
import "../../../App.css";
import { Heading, Link, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

function LiteraryCriticism() {
  return (
    <div id="research">
      <Text className="research-card-text">
        В конце 1990-х гг. Г.Г. Граник с соавт. стали разрабатывать новое
        научное направление –{" "}
        <i>«Школьное литературоведение на психологической основе»</i>. В отличие
        от Б.М. Теплова, предложившего поставить художественную литературу на
        службу научным исследованиям по психологии, идея Г.Г. Граник состояла в
        том, чтобы поставить психологию на службу изучения литературы в школе
        [1]. Учебные книги нового – психодидактического – типа призваны
        способствовать эмоциональному, личностному и когнитивному развитию
        учащихся в процессе чтения и осмысления художественных текстов.
      </Text>
      <Text className="research-card-text">
        В рамках данного направления авторским коллективом под рук. Г.Г. Граник
        были написаны, апробированы и изданы семь учебных книг по литературе для
        учащихся средних и старших классов: «И… снова о Пушкине» (1999),
        «Литература. Учимся понимать художественный текст: Задачник-практикум.
        8-11 классы» (1999; 2001), «Драматурги, драматургия, театр» (2001),
        «Русская литература: от былин до Крылова» (2007), «Я – другой. М.Ю.
        Лермонтов» (2012), «Роман А.С. Пушкина “Евгений Онегин”. Читаем,
        размышляем» (2016), «А.С. Пушкин, его друзья и современники» (2019;
        2023) [2; 3; 4; 5; 6; 7; 8].
      </Text>
      <Text className="research-card-text">
        Ведущим принципом отбора и построения материала в учебных книгах нового
        типа является возбуждение и поддержание познавательного интереса
        подростков к писателю, его творчеству и мировоззрению. Так, авторы
        разработали особый способ подачи биографического материала, когда
        школьники встречаются не с хрестоматийным <i>гением</i>, а с живым
        человеком, который предстает как уникальная личность на фоне своей
        эпохи. В результате знакомство с биографией не выступает в качестве
        «довеска» к изучению художественных произведений, а становится одним из
        способов обратить школьника к книге, помогает формировать общую культуру
        ученика. Одной из представленных в учебных книгах форм работы с текстами
        является выполнение литературных задач, что позволяет включить учащихся
        в процесс активного анализа и интерпретации изучаемых произведений. При
        выполнении этих задач учащимся необходимо постоянно возвращаться к
        прочитанному, выражать свое мнение, обосновывая его с опорой на текст.
      </Text>
      <Text className="research-card-text">
        Отдельной исследовательской проблемой стала разработка и апробация
        системы психодидактических принципов и приемов развития у подростков
        понимания лирической поэзии [9]. Данная система была реализована К.В.
        Мироновой под руков. Г.Г. Граник в посвященных лирике главах книг о
        жизни и творчестве А.С. Пушкина и М.Ю. Лермонтова (главы «Беседы о
        лирике А.С. Пушкина» и «Одиннадцать поэтических шедевров») [7; 8]. Для
        каждой из содержащихся в этих главах бесед был разработан свой сценарий
        рассмотрения стихотворения, воплощена методика вдумчивой поэтапной
        работы с <i>поэтическим словом</i>. В ходе экспериментального обучения
        была выявлена эффективность использования данных глав для развития у
        подростков понимания лирики [10]. Беседы на страницах учебных книг
        направляют школьников в русло личностно значимого взаимодействия с
        поэтическим текстом, предоставляя им возможности для участия в
        совместном творческом исследовании и помогая открыть для себя богатство
        различных чувств, размышлений, трудно уловимых переходов душевных
        состояний.
      </Text>
      <Text className="research-card-text">
        В учебных книгах нового типа используется особая форма общения со
        школьниками – «устная речь в ее письменной форме». Специфика этого языка
        заключается в соединении интонационного богатства и выразительности
        живой устной речи со связностью и нормативностью письменной речи.
        Благодаря этому облегчается процесс восприятия текста, сокращается
        дистанция между читателем и авторами книги, создавая своеобразный эффект
        их совместного присутствия в пространстве полилога. Задаваемые в
        процессе обсуждения вопросы направлены на то, чтобы побудить подростка к
        размышлению, более глубокому осмыслению текста, не позволять ему
        оставаться на уровне пассивного восприятия. Работа с учебной книгой
        помогает развивать художественный вкус школьников, который дает человеку
        возможность получать наслаждение от подлинно прекрасного произведения
        искусства, разбираться в нем, постигая выраженное в тексте авторское
        мировосприятие и высказывая собственное обоснованное суждение.
      </Text>
      <Text className="research-card-text">
        Все книги, создаваемые в рамках{" "}
        <i>«школьного литературоведения на психологической основе»</i>,
        способствуют решению важнейшей задачи гуманитарного образования –
        воспитание настоящего читателя, для которого чтение является
        потребностью, формой духовной жизни и средством созидания самого себя
        как личности.
      </Text>
      <Heading className="research-card-heading">Основные публикации</Heading>
      <Text className="research-card-text">
        <ol className="research-list">
          <li>
            Граник Г.Г. Проблема создания школьного литературоведения на
            психологическом фундаменте // Мир психологии. 2016. № 1 (85). С.
            274-279. eLIBRARY ID: 26599404
          </li>
          <li>
            Граник Г.Г., Бондаренко С.М., Концевая Л.А., Шаповал Л.А.
            Литература. Учимся понимать художественный текст:
            Задачник-практикум. 8–11 классы. М., 1999, 2001.
          </li>
          <li>
            Граник Г.Г., Концевая Л.А. Драматурги, драматургия, театр. М.:
            Владос, 2001.
          </li>
          <li>
            Граник Г.Г., Концевая Л.А. И… снова о Пушкине. М.: Илекса, 1999.
          </li>
          <li>
            Граник Г.Г., Концевая Л.А. Роман А.С. Пушкина “Евгений Онегин”.
            Читаем, размышляем. М.: Илекса, 2016. eLIBRARY ID: 26661492
          </li>
          <li>
            Граник Г.Г., Концевая Л.А. Русская литература. От былин до Крылова.
            М.: Баласс, 2007.
          </li>
          <li>
            Граник Г.Г., Концевая Л.А., Миронова К.В. А.С. Пушкин, его друзья и
            современники: Учебное пособие по литературе для учащихся 7-9 классов
            общеобразовательных организаций. М.: Мнемозина, 2019; 2023 (2-е
            изд.). eLIBRARY ID: 36700070
          </li>
          <li>
            Граник Г.Г., Концевая Л.А., Динерштейн Е.Е., Миронова К.В. Я –
            другой. М.Ю. Лермонтов: Учебная книга по русской филологии. М.:
            Илекса, 2012. eLIBRARY ID: 26709026
          </li>
          <li>
            Миронова К.В. Психодидактические основы становления у подростков
            способности к пониманию лирической поэзии // Перспективы науки и
            образования. 2021. № 6 (54). С. 330-341. DOI:
            <Link
              color="#43129B"
              href="https://cyberleninka.ru/article/n/psihodidakticheskie-osnovy-stanovleniya-u-podrostkov-sposobnosti-k-ponimaniyu-liricheskoy-poezii/viewer"
              isExternal
            >
              10.32744/pse.2021.6.22
              <ExternalLinkIcon mx="2px" />
            </Link>
          </li>
          <li>
            Миронова К.В. Психодидактические условия развития у подростков
            понимания лирической поэзии: дисс. … канд. психол. наук. М., 2021.
            eLIBRARY ID: 46531150
          </li>
        </ol>
      </Text>
    </div>
  );
}

export default LiteraryCriticism;
