import Header from '../../layout/Header.jsx';
import Footer from '../../layout/Footer.jsx';
import './Experiment.scss';
import '../../../App.css';
import { Link } from "@chakra-ui/react";

function Text7() {
  return (
    <div>
      <Header />
        <main className="experiment"><br />
        <article className="exp-article">
          <Link textDecoration="underline" color="#43129B" className="link-back" href="/experiment-2024 ">НАЗАД</Link><br />
          <p>Происхождение Ибрагима Ганнибала до конца не выяснено. По основной версии, просуществовавшей двести лет, его родиной была Абиссиния на северо-востоке Африки (современная Эфиопия), а сам он был из древнего княжеского рода. По другой версии, возникшей в конце XX в., прадед Пушкина был родом из Камеруна – государства в западной части Центральной Африки. Согласно «камерунской» версии происхождения Ганнибала, он родился в Султанате Логóн, в городе Логóн-Бирни, на границе между Камеруном и Чадом. Впервые это предположение ещё в 1962 г. высказал писатель Владимир Набоков, а развил его африканский славист из Бенина Дьёдонне Гнамманку.</p>
          <p><b>Источники:</b> Данилов И.В. Прадед Пушкина Ганнибал: Материалы африканской экспедиции. СПб., 2001; Лурье Ф. Абрам Ганнибал. Африканский прадед русского гения. СПб.: Вита Нова, 2012; Гнамманку Д. Абрам Ганнибал. Чёрный предок Пушкина. М.: Молодая гвардия, 1999.</p>
        </article><br />
        </main>
      <Footer />
    </div>
  );
}

export default Text7;