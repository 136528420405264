import "./Research.scss";
import "../../../App.css";
import { Heading, Link, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

function Reading() {
  return (
    <div id="research">
      <Text className="research-card-text">
        К числу приоритетных исследований, проводимых научным коллективом в
        настоящее время, относятся: проблема трансформации чтения школьников в
        новых социокультурных условиях, феномен цифрового чтения, эмпирические
        исследования понимания текста учащимися 5-9 классов в процессе
        смыслового чтения с бумажных и электронных носителей. Результаты
        исследований отражены в ряде публикаций (см.).
      </Text>
      <Heading className="research-card-heading">Бэкграунд</Heading>
      <Text className="research-card-text">
        Данное направление является продолжением идей Г.Г. Граник с сотр. в
        области понимания текста (первые исследования проведены в 1980-е гг.
        (см., напр.: Граник Г.Г., Бондаренко С.М., Концевая Л.А. Как учить
        работать с книгой. М., 1995; Граник Г.Г., Бондаренко С.М., Концевая Л.А.
        Дорога к книге. М., 1996; Граник Г.Г., Концевая Л.А. Восприятие
        школьниками художественного текста // Вопросы психологии. 1996. № 3. С.
        43–52). Мы исходили из того, что концептуальные представления о
        понимании текста, разработанные в научной школе Г.Г. Граник
        применительно к традиционному чтению с листа, обладают несомненным
        развивающим потенциалом и могут быть положены в основу исследований
        цифрового чтения. В частности, необходимо было получить ответы на
        следующие вопросы: каковы предпочтения подростков в отношении формата
        чтения, как изменение формы представления текста влияет на смысловую
        сторону чтения, существуют ли качественные различия в понимании текста,
        воспринимаемого на разных носителях, и др.? С этой целью был проведен
        ряд теоретико-эмпирических исследований чтения с экрана (на выборке
        средних подростков).
      </Text>
      <Heading className="research-card-heading">
        Социолого-психологическое исследование чтения подростков (2020)
      </Heading>
      <Text className="research-card-text">
        В феврале-марте 2020 г. нами было проведено массовое онлайн-исследование
        «Бумага vs экран (чтение с листа и с экрана)», в котором приняли участие
        1835 школьников V-VIII классов (45,3% мальчиков и 54,7% девочек) из 14
        регионов РФ (возраст учащихся – 11–15 лет, M=12,7). В результате были
        выявлены предпочтения подростков относительно формата чтения книг:
        доминирующим форматом остается чтение с листа – на него указали более
        половины (58,3%) школьников, соответственно с экрана – 41,7%. Цифровое
        чтение подростков представлено чтением так наз. ленты (посты,
        комментарии в соцсетях) и сообщений в чатах (56,6%), популярно также
        чтение блогов, новостей, различных справочных материалов,
        образовательных ресурсов. Характер чтения с экрана во многом
        определяется тем, с какого устройства воспринимается текст. Основной тип
        используемого в подростковой среде девайса – смартфон/мобильный телефон
        (73,5%).
      </Text>
      <Text className="research-card-text">
        В ходе онлайн-опроса выявлена связь цифрового чтения с типом текста. При
        чтении информационных текстов предпочтительнее экран (40,8%
        респондентов), при чтении художественных – бумага (52,4%), остальные
        информанты предпочтений не высказали.
      </Text>
      <Text className="research-card-text">
        Один из важных выводов исследования: в настоящее время мы переживаем
        переходный период в чтении, проявляющийся в сосуществовании чтения с
        листа и чтения с экрана при доминировании традиционного формата (см.
        подр.: [7]).
      </Text>
      <Heading className="research-card-heading">
        Сравнительный анализ уровня понимания текста с листа и с экрана
        (2021-2022)
      </Heading>
      <Text className="research-card-text">
        Самостоятельной исследовательской задачей стало выявление особенностей
        понимания текста с экрана (в сравнении с чтением с листа). Проведенный
        теоретический анализ по проблемам цифрового чтения [1; 3] показал, что в
        литературе имеется большой массив данных, позволяющих предполагать
        существование различий в паттернах восприятия текста с листа и с экрана,
        однако эмпирических исследований на русскоязычной выборке испытуемых,
        явно недостаточно.
      </Text>
      <Text className="research-card-text">
        В 2021-2022 гг. сотрудниками группы было проведено сравнительное
        исследование уровня понимания школьниками 11–16 лет бумажных и
        электронных текстов в процессе смыслового чтения. Выборку составили 712
        учащихся V–IX классов шести российских школ. В ходе констатирующего
        эксперимента участники заполняли анкету, касающуюся чтения с экрана и с
        листа, и выполняли задания письменной диагностики на понимание
        повествовательного текста (фрагментов из книги Ф. Конюхова «На грани
        возможностей» о плавании вокруг Антарктиды).
      </Text>
      <Text className="research-card-text">
        Основные выводы:
        <ol className="research-list">
          <li>
            Обнаружено и статистически подтверждено отсутствие значимых различий
            в уровне понимания текста подростками при чтении с экрана и с листа
            (p {">"} 0,05). Возможно, это связано с психологическими
            особенностями цифровых поколений Z (зет) и A (альфа) – адаптацией
            подростков к цифровому чтению в результате самонаучения.
          </li>
          <li>
            Выявлена планомерная положительная динамика понимания текста при
            переходе в более старшие классы – от V класса к IX (р ≤ 0,001).
            Однако во всех параллелях преобладает поверхностное понимание, о чем
            свидетельствует тот факт, что проблемы с определением и
            формулированием концепта текста (главного показателя понимания) в
            V-VI классах испытывают 80,6% учащихся, в VII-VIII – 73,3%, в IX –
            57,3%.
          </li>
          <li>
            Хотя различия в уровне понимания текста статистически незначимы,
            различия имеются в самих способах чтения и способах работы с
            текстом, которые связаны с особенностями носителя и виртуальной
            структуры электронного текста. Подтверждено, что основными
            стратегиями чтения с экрана являются скроллинг и чтение-сканирование
            (некоторые подростки пользуются также функцией автоматического
            поиска).
          </li>
          <li>
            Выявлена тенденция понимания текста на уровне «общее – частное»:
            вычитывание общего смысла и главной мысли практически не зависит от
            носителя и остается на низком уровне для обоих носителей (высокий
            уровень – у 5%), в то время как понимание фактов для многих
            подростков оказывается эффективнее при чтении с экрана. При этом
            понимание текста чаще всего сводится к извлечению из него некоторых
            фактов, а подтекст и концепт остаются во многом непонятыми.
          </li>
          <li>
            Полученные данные позволяют заключить, что, хотя современные
            подростки достаточно успешно адаптируются к обоим форматам чтения, у
            большинства имеются серьезные проблемы в области читательской
            грамотности, относящиеся как к традиционному бумажному, так и к
            цифровому чтению, а это, в свою очередь, отрицательно сказывается на
            учебно-познавательной деятельности на всех ступенях образования [1;
            2; 3].
          </li>
        </ol>
      </Text>
      <Heading className="research-card-heading">Публикации</Heading>
      <Text className="research-card-text">
        <ol className="research-list">
          <li>
            Миронова К.В., Борисенко Н.А., Шишкова С.В. Возрастная динамика
            понимания текста подростками 11–16 лет в процессе смыслового чтения
            с экрана и с листа // Вопросы психологии. 2023. № 1. С. 27-39.
          </li>
          <li>
            Борисенко Н.А., Миронова К.В., Шишкова С.В. Вклад научной школы Г.Г.
            Граник в психологию понимания: от бумажного текста к электронному //
            Теоретическая и экспериментальная психология. 2022. Т. 15. № 3. С.
            170-186. DOI:
            <Link
              color="#43129B"
              href="https://tepsyj.ru/articles/article/1659/"
              isExternal
            >
              10.24412/2073-0861-2022-3-170-186
              <ExternalLinkIcon mx="2px" />
            </Link>
          </li>
          <li>
            Миронова К.В., Борисенко Н.А., Шишкова С.В. Понимание текста
            подростками 11-12 лет в процессе смыслового чтения с бумажных и
            электронных носителей // Вопросы психологии. 2022. № 2. С. 99-110.
          </li>
          <li>
            Борисенко Н.А. Читательские предпочтения подростков относительно
            выбора практик чтения: экран vs бумага // Человек читающий: Homo
            legens–14. Сборник научных статей / под ред. М.В. Белоколенко. М.:
            Русская ассоциация чтения; ООО «Буки Веди», 2022. С. 30-44.
          </li>
          <li>
            Граник Г.Г., Борисенко Н.А., Миронова К.В., Шишкова С.В.
            Функционирование воображения подростков при чтении бумажных и
            электронных текстов // Вопросы психологии. 2021. № 1. С. 38-50.
          </li>
          <li>
            Борисенко Н.А. Цифровая образовательная платформа как ресурс
            формирования новых учебных читательских практик // Доклады Научного
            совета по проблемам чтения РАО. Вып. 18. Материалы Постоянного
            Круглого стола «Чтение как социально значимая практика» / сост. Ю.П.
            Мелентьева; под ред. В.А. Лекторского. М.: ФГБУН «Наука» РАН, 2021.
            С. 9-18.
          </li>
          <li>
            Борисенко Н.А., Миронова К.В., Шишкова С.В., Граник Г.Г. Особенности
            цифрового чтения современных подростков: результаты
            теоретико-эмпирического исследования // Science for Education Today.
            2020. Т. 10. № 5. С. 28-49. DOI:
            <Link
              color="#43129B"
              href="http://dx.doi.org/10.15293/2658-6762.2005.02"
              isExternal
            >
              10.15293/2658-6762.2005.02
              <ExternalLinkIcon mx="2px" />
            </Link>
          </li>
        </ol>
      </Text>
    </div>
  );
}

export default Reading;
