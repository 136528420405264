import { forwardRef } from 'react';
import '../Staff.scss';

const Awards = forwardRef((props, ref) => {
    return (
        <article ref={ref} className='staff-article'>
            <h2>Награды и благодарности</h2>
            <ul>
            <li>Диплом I степени в номинации «Психология – современной школе» на Всероссийском
            конкурсе, проводимом Российский психологическим обществом (2012), за учебную книгу «Я –
            другой. М.Ю. Лермонтов» (в составе авторского коллектива: Г.Г. Граник, Л.А. Концевая, Е.Е.
            Динерштейн, К.В. Миронова);</li>
            <li>Лауреат конкурса на лучшее владение устным китайским языком «Говорим по-китайски»
            (2010). Лауреат конкурсов эссе «Прочитать тысячу книг, пройти тысячу верст…» (2013), «Я и
            Китай» (2018);</li>
            <li>Государственный стипендиат 2012-2013 гг. в номинации «Талантливый молодой автор России»;</li>
            <li>Благодарность НИУ ВШЭ за участие в организации и проведении симпозиума «Оценка
            эффектов в трансформациях образования», прошедшего в рамках XVIII Апрельской
            международной конференции 11-14 апреля 2017 г.;</li>
            <li>Благодарности ФГБНУ ПИ РАО за работу в экспертном жюри Всероссийского форума научной
            молодежи «Шаг в будущее», секция «Психология», в 2017-2023 гг.</li>
            </ul>
            <br />
        </article>
        );
});

export default Awards;